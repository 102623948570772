import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(5),
      boxShadow: "0px 8px 16px 0px rgb(0 0 0 / 15%)",
    },
    heading: {
      fontWeight: 500,

      "& span": {
        color: theme.palette.secondary.main,
      },
    },
  })
);

const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email")
    .required("Email field is required"),
  password: Yup.string().required("Password field is required"),
});

const LoginForm: React.FC = () => {
  const classes = useStyles();
  return (
    <Paper className={classes.root} variant="outlined">
      <Typography variant="h4" className={classes.heading} align="center">
        Get started with <span>Spoclearn</span>
      </Typography>

      <Box marginTop={3}>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={SignInSchema}
          onSubmit={() => {}}
        >
          {({ submitForm, isSubmitting, errors, touched }) => (
            <Form autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    name="email"
                    type="email"
                    label="Email"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    placeholder="Enter your email"
                    error={Boolean(errors.email) && touched.email}
                    helperText={errors.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={TextField}
                    type="password"
                    label="Password"
                    name="password"
                    fullWidth
                    variant="outlined"
                    margin="normal"
                    placeholder="Enter your password"
                    error={Boolean(errors.password) && touched.password}
                    helperText={errors.password}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                    fullWidth
                    size="large"
                    style={{ marginTop: 16 }}
                  >
                    {isSubmitting ? <CircularProgress size={27} /> : "Login"}
                  </Button>
                </Grid>
              </Grid>

              <Box marginTop={3} textAlign="center">
                <Link href="#">Or sign in using your Google Account</Link>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Paper>
  );
};

export default LoginForm;
